
import React from 'react';
import { Admin, Resource } from 'react-admin';

import dataProvider from './dataProvider';

import {DriverList,DriverShow,LiveTripList,SosList,SosShow,CompletedTripList,LiveTripShow} from './list/driverList';
import {DriverCreate,VehicleCreate,VehicleTypeCreate,SosEdit, DriverEdit,VehicleTypeEdit} from './forms/driverForm';

import {VehicleList,VehicleTypeList} from './list/vehicleList';
import {AppSettingsList} from './list/appSettings';
import {AppSettingsCreate, AppSettingsEdit} from './forms/appSettings';
import {authProvider} from "./util/authProvider";


const App = () => (
  <Admin title="Ausa Admin" dataProvider={dataProvider} authProvider={authProvider}>
      <Resource options={{ label: 'SOS Requests' }} name="sos_ticket" list={SosList} edit={SosEdit} show={SosShow}/>
      <Resource options={{ label: 'Live Trips' }} name="liveTrip" list={LiveTripList} show = {LiveTripShow}/>
      <Resource options={{ label: 'Completed Trips' }} name="completed_trip" list={CompletedTripList} show = {LiveTripShow}/>
      <Resource options={{ label: 'Drivers' }} name="driver" list={DriverList} show={DriverShow} edit={DriverEdit} create={DriverCreate}/>
      <Resource options={{ label: 'Vehicles' }} name="vehicle" list={VehicleList} create={VehicleCreate}  />
      <Resource name="open-vehicle" />
      <Resource options={{ label: 'Vehicle Types' }} name="vehicleType" list={VehicleTypeList} create={VehicleTypeCreate} edit={VehicleTypeEdit} />
      <Resource options={{ label: 'App Settings' }} name="appSettings" list={AppSettingsList} create={AppSettingsCreate} edit={AppSettingsEdit}  />
  </Admin>
);

export default App;
