import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const styles = {
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2,
    },
};

const OpenLocationField = ({ record = {}, source, classes,...props }) =>

    <a href={"https://www.google.com/maps/search/?api=1&query="+record[source]} className={classes.link}
        {...props}>
        {props.label}
        <LaunchIcon className={classes.icon} />
    </a>;

export default withStyles(styles)(OpenLocationField);