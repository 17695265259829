import React from 'react';
import { List,Show, Datagrid, TextField, EmailField ,NumberField,SelectField,SimpleShowLayout,DateField } from 'react-admin';
import OpenLocationField from '../components/MapUrlField';
import {TripActionButton} from '../components/tripActionButton';
import { TRIP_STATUS_CHOICES } from '../util/constants';


export const DriverList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <SelectField label={"Vehicle Status"} source="vehicle.status" choices={[
                { id: 0, name: 'Offline' },
                { id: 1, name: 'Online' },
                { id: 2, name: 'On Trip' },
                { id: 4, name: 'Requested' },
                ]} />
            <SelectField label={"Supported Mode"}  source="vehicle.bookingModeSupported" choices={[
                { id: 0, name: 'Rides Onlys' },
                { id: 1, name: 'Delivery Only' },
                { id: 2, name: 'Ride & Delivery' },
                ]} />
            <SelectField label={"Account Status"}  source="vehicle.accountStatus" choices={[
                 { id: 0, name: 'Blocked' },
                 { id: 1, name: 'Active' },
                ]} />
            <TextField source="phoneNumber" />
            <TextField label={"Logical Rating"} source="vehicle.logicalRating" />
            <TextField label={"Vehicle Reg No"} source="vehicle.regNo" />
        </Datagrid>
    </List>
);

export const DriverShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <SelectField label={"Vehicle Status"} source="vehicle.status" choices={[
                { id: 0, name: 'Offline' },
                { id: 1, name: 'Online' },
                { id: 2, name: 'On Trip' },
                { id: 4, name: 'Requested' },
                ]} />
            <TextField source="phoneNumber" />
            <TextField label={"Vehicle Reg No"} source="vehicle.regNo" />
            <NumberField source="walletBalance" />
            <EmailField source="email" />
            <TextField source="dlNo" />
            <TextField source="password" />
        </SimpleShowLayout>
    </Show>
);

export const LiveTripList = props => (
    <List {...props}>
        <Datagrid rowClick="show" >
            <DateField label="Booking Time" showTime source="createdAt" />
            <SelectField label="Status" source="status" choices={TRIP_STATUS_CHOICES} />
            <TextField label="Commuter" source="commuter.name" />
            <TextField label="Commuter Phone"source="commuter.phoneNumber" />
            <TextField label="Driver" source="driver.name" />
            <TextField label="Vehicle" source="driver.vehicle.regNo" />
            <TextField label="Pickup"source="srcAddress" />
            <TextField label="Selected drop location" source="dstAddress" />
            <TextField label="Droped at location"source="actualDstAddress" />
            <NumberField source="fare"/>
            <TextField label="OTP"source="otp" />
        </Datagrid>
    </List>
);

export const CompletedTripList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <DateField label="Booking Time" showTime source="createdAt" />
            <TextField label="Commuter" source="commuter.name" />
            <TextField label="Commuter Phone"source="commuter.phoneNumber" />
            <TextField label="Driver" source="driver.name" />
            <TextField label="Vehicle" source="driver.vehicle.regNo" />
            <TextField label="Pickup"source="srcAddress" />
            <TextField label="Drop"source="actualDstAddress" />
            <NumberField source="fare"/>
        </Datagrid>
    </List>
);

export const SosList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <DateField label="Time" showTime source="createdAt" />
            <TextField label="Reason" source="reason" />
            <TextField label="Reason detail" source="reasonText" />
            <TextField label="Commuter" source="commuter.name" />
            <TextField label="Commuter Phone"source="commuter.phoneNumber" />
            <TextField label="Driver" source="driver.name" />
            <TextField label="Vehicle" source="driver.vehicle.regNo"/>
        </Datagrid>
    </List>
);
export const SosShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <DateField label="Time" showTime source="createdAt" />
            <TextField label="Reason" source="reason" />
            <TextField label="Reason detail" source="reasonText" />
            <TextField label="Commuter" source="commuter.name" />
            <TextField label="Commuter Phone"source="commuter.phoneNumber" />
            <TextField label="Driver" source="driver.name" />
            <TextField label="Vehicle" source="driver.vehicle.regNo" />
            <TextField label="Pickup"source="trip.srcAddress" />
            <TextField label="Drop"source="trip.dstAddress" />
            <OpenLocationField label="Open Live Location" source="liveLocation"/>
            <OpenLocationField label="Open Created At Loaction" source="driverLoc"/>
        </SimpleShowLayout>
    </Show>
);


export const LiveTripShow = props => (
    <Show {...props}>
        <SimpleShowLayout >
            <DateField label="Booking Time" showTime source="createdAt" />
            <SelectField label="Status" source="status" choices={TRIP_STATUS_CHOICES} />
            <TextField label="Commuter" source="commuter.name" />
            <TextField label="Commuter phone" source="commuter.phoneNumber" />
            <TextField label="Driver" source="driver.name" />
            <TextField label="Vehicle" source="driver.vehicle.regNo" />
            <TextField label="Pickup" source="srcAddress" />
            <TextField label="Selected drop location" source="dstAddress" />
            <TextField label="Droped at location"source="actualDstAddress" />
            <TextField label="duration" source="duration" />
            <TextField label="duration in traffic" source="duration_in_traffic" />
            <TextField label="Waiting charge" source="waiting_charge" />
            <NumberField source="fare"/>
            <TripActionButton />
        </SimpleShowLayout>
    </Show>
);
