import React from 'react';
import { List, Datagrid, TextField} from 'react-admin';

export const AppSettingsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField label={"Key"} source="key" />
            <TextField label={"Value"} source="value" />
            <TextField label={"Type"} source="type" />
        </Datagrid>
    </List>
);