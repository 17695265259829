export const BASE_URL = 'https://auto.onedi.in';
//export const BASE_URL = 'http://127.0.0.1:3000';
export const AUTH_TOKEN_KEY = 'auth_token';

export const TRIP_STATUS = {
    CREATED: 0,
    ASSIGNED: 1,
    STARTED: 2,
    ARRIVED: 3,
    ON_TRIP: 4,
    DROP: 5,
    COMPLETED: 6,
    CANCELED: 7,
    RATED: 8,
    TIME_OUT: 9,
    NO_SHOW: 10,
};

export const TRIP_STATUS_CHOICES = [
    { id: TRIP_STATUS.CREATED, name: 'REQUETSED' },
    { id: TRIP_STATUS.STARTED, name: 'ON PICKUP' },
    { id: TRIP_STATUS.ARRIVED, name: 'AT PICKUP LOCATION' },
    { id: TRIP_STATUS.ON_TRIP, name: 'ON TRIP' },
    { id: TRIP_STATUS.DROP, name: 'DROP COMPLETE' },
    { id: TRIP_STATUS.COMPLETED, name: 'TRIP COMPLETED' },
]