import React from 'react';
import { Create, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, TextField, SelectField } from 'react-admin';

export const DriverCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Driver Name" source="name" />
            <TextInput label="Phone" source="phoneNumber"/>
            <TextInput label="Email" source="email"/>
            <TextInput label="Driving License No" source="dlNo"/>
            <ReferenceInput label="Select Vehicle" source="vehicle" reference="open-vehicle">
                <SelectInput optionText="regNo" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
export const DriverEdit = (props) => (
    <Edit title="Change Driver Status" {...props}>
        <SimpleForm>
            <SelectInput label="Select Driver Vehicle Status" source="status" choices={[
                { id: 0, name: 'Offline' },
                { id: 1, name: 'Online' },
            ]} />
            <SelectInput label="Select Supported Booking Mode" source="bookingModeSupported" choices={[
                { id: 0, name: 'Rides Onlys' },
                { id: 1, name: 'Delivery Only' },
                { id: 2, name: 'Both' },
            ]} />
            <SelectInput label="Select Driver Account Status" source="accountStatus" choices={[
                { id: 0, name: 'Blocked' },
                { id: 1, name: 'Active' },
            ]} />
            <TextInput label="Name" source="name" />
            <NumberInput label="Logical rating" source="logicalRating" />
        </SimpleForm>
    </Edit>
);


export const VehicleCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Vehicle Reg No" source="regNo" />
            <ReferenceInput label="Select Vehicle Type" source="vehicleType" reference="vehicleType">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput label="Select Driver Vehicle Supported Booking Modes" source="bookingModeSupported" choices={[
                { id: 0, name: 'Rides Onlys' },
                { id: 1, name: 'Delivery Only' },
                { id: 2, name: 'Both' },
            ]} />
        </SimpleForm>
    </Create>
);

export const VehicleEdit = (props) => (
    <Edit title="Take Action" {...props}>
        <SimpleForm>
            <SelectInput label="Change Driver Status" source="status" choices={[
                 { id: 0, name: 'Offline' },
                 { id: 1, name: 'Online' },
                 ]} />
            ]} />
        </SimpleForm>
    </Edit>
);

export const VehicleTypeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Type eg: Auto" source="name" />
            <NumberInput label="Base fare" source="baseFare" />
            <NumberInput label="Fare" source="fare" />
            <NumberInput label="Free km" source="freeKm" />
            <NumberInput label="Waiting Charge" source="waitingCharge" />
        </SimpleForm>
    </Create>
);

export const SosEdit = (props) => (
    <Edit title="Take Action" {...props}>
        <SimpleForm>
            <SelectInput label="Change Sos Status" source="status" choices={[
                { id: 1, name: 'Ticket Resolved' },
            ]} />
        </SimpleForm>
    </Edit>
);
export const VehicleTypeEdit = (props) => (
    <Edit title="Edit Vehicle Type" {...props}>
        <SimpleForm>
            <TextInput label="Type eg: Auto" source="name" />
            <NumberInput label="Base fare" source="baseFare" />
            <NumberInput label="Fare" source="fare" />
            <NumberInput label="Free km" source="freeKm" />
            <NumberInput label="Waiting Charge" source="waitingCharge" />
        </SimpleForm>
    </Edit>
);