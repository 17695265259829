import { BASE_URL, AUTH_TOKEN_KEY } from './constants';

export const authProvider = {
    login:  async({ username, password }) =>  {
        const request = new Request(`${BASE_URL}/api/auth/adminLogin`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if(auth.success === false){
                    return Promise.reject(auth.msg);
                }
                localStorage.setItem(AUTH_TOKEN_KEY, auth.token);
                localStorage.setItem('name', auth.data.name);
            });
    },
    logout: () => {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem(AUTH_TOKEN_KEY)
        ? Promise.resolve()
        : Promise.reject(),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem(AUTH_TOKEN_KEY);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
    getIdentity: () => Promise.resolve(),
};
