import React from 'react';
import { List, Datagrid, TextField, SelectField ,NumberField, ReferenceField, Show, SimpleShowLayout} from 'react-admin';

export const VehicleList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField label={"Vehicle Reg No"} source="regNo" />
            <SelectField label={"Vehicle Status"}  source="status" choices={[
                { id: 0, name: 'Offline' },
                { id: 1, name: 'Online' },
                { id: 2, name: 'On Trip' },
                { id: 4, name: 'Requested' },
                ]} />
            <SelectField label={"Supported Mode"}  source="bookingModeSupported" choices={[
                { id: 0, name: 'Rides Onlys' },
                { id: 1, name: 'Delivery Only' },
                { id: 2, name: 'Ride & Delivery' },
                ]} />
            <TextField label="Type" source="vehicleType.name" />
        </Datagrid>
    </List>
);
export const VehicleTypeList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <NumberField source="fare" />
            <NumberField source="baseFare" />
            <NumberField source="freeKm" />
            <NumberField label = "Waiting charge per min" source="waitingCharge" />
        </Datagrid>
    </List>
);
