import React from 'react';
import { Create, Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';

export const AppSettingsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="Key" source="key" />
            <TextInput label="Value" source="value"/>
            <SelectInput label="Type" source="type" choices={[
                 { id: 1, name: 'Number' },
                 { id: 2, name: 'String' },
                 ]} />
        </SimpleForm>
    </Create>
);

export const AppSettingsEdit = (props) => (
    <Edit title="Edit Settings" {...props}>
        <SimpleForm>
            <TextInput label="Change value" source="value" />
        </SimpleForm>
    </Edit>
);