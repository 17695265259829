import * as React from "react";
import { useMutation, Button, useNotify, useRedirect, } from 'react-admin';
import { TRIP_STATUS } from '../util/constants';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    button: {
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        backgroundColor: '#f1c40f',
        color: '#000',
        paddingRight: 20,
        paddingLeft: 20,
    },
});
export const TripActionButton = ({ record }) => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();

    const [updateTrip, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'liveTrip',
            payload: { id: record.id, data: { trip: record.id } },
        },
        {
            onSuccess: ({ data }) => {
                redirect('/liveTrip');
                notify('Updated');
            },
            onFailure: (error) => {
                notify(`Trip Update error`);
                redirect('/liveTrip');
            },
        }
    );
    const getButtonActionText = (status) => {
        switch (status) {
            case TRIP_STATUS.ON_TRIP:
                return 'Complete Trip';
            case TRIP_STATUS.DROP:
                return 'Mark Payment Recieved';
            default:
                return 'No Action';
        }
    }

    return(
        record && getButtonActionText(record.status) !== '' ? 
            <Button 
                label={getButtonActionText(record.status)} 
                onClick={updateTrip} 
                disabled={loading}
                className={classes.button}
                />
        : null
    );
};